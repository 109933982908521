import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';
import { watch } from 'vue';

export default defineNuxtRouteMiddleware(() => {
  // TODO: delete once aanvragen golive
  showError({
    statusCode: 404,
    message: 'Deze pagina bestaat niet',
  });
  return;

  const auth = useAuthStore();

  let shouldNavigateToLogin = false;
  let shouldNavigateToOffer = false;

  watch(
    [
      () => auth.authenticated,
      () => auth.isLoading,
    ],
    () => {
      if (!auth.isLoading && !auth.authenticated) {
        shouldNavigateToLogin = true;
      }

      if (!auth.isBorrower) {
        shouldNavigateToOffer = true;
      }
    },
    {
      immediate: true,
    },
  );

  if (shouldNavigateToLogin) {
    return navigateTo({ path: '/login' });
  }

  if (shouldNavigateToOffer) {
    return navigateTo({ path: '/aanbod' });
  }
});
